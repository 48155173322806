/**
 * format the date for billing date
 * @param {number} timestamp
 * @returns
 */
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

export function _billingDateFormat(timestamp) {
    const date = new Date(timestamp);
    const month = date.toLocaleDateString('default', { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}

function PlanDetail({ label, value }) {
    return (
        <h6>
            {label}
            <span className='pink-text'>{value}</span>
        </h6>
    )
}

export default function SubscriptionPaymentCard({ preview, interval = '' }) {
    const router = useRouter()
    const isAnnual = interval === 'annual'
    const amount = isAnnual ? preview?.amountInfo?.estimatedAnnualPrice : preview?.amountInfo?.amount
    return (
            <div className="subscription-deatils">
                <div className="plan-card">
                    <div className="standard-plan">
                        <h4>{preview?.name}
                            <span className="pink-text">${amount}</span>
                        </h4>
                    </div>
                    {preview?.nextBillingDate &&
                        <div className="details">
                            <PlanDetail label='Next Payment On' value={_billingDateFormat(preview?.nextBillingDate)} />
                            {isAnnual ? <PlanDetail label='Discount' value={preview?.price?.annual?.discount} /> : null}
                            <PlanDetail label='Payable Amount' value={`$${preview?.amountInfo?.amount}`} />
                        </div>
                    }

                </div>
                <p className="Text-Left">
                    The subscription automatically renews. You can cancel your subscription anytime from
                    the manage subscription page. For more information please visit our&nbsp;
                    <span>
                        <Link href={{
                            pathname: "/PrivacyPolicy/termsAndCondition", query: {
                                sub: true,
                                pa: router.asPath
                            }
                        }}
                        legacyBehavior
                        >
                            <a className="underline-link">Terms & Conditions</a>
                        </Link>
                    </span> and &nbsp;
                    <span>
                        <Link href={{
                            pathname: "/PrivacyPolicy/privacy", query: {
                                sub: true,
                                pa: router.asPath
                            }
                        }} legacyBehavior>
                            <a className="underline-link">Privacy Policy</a>
                        </Link>.
                    </span>
                </p>


            </div>
    )
}
SubscriptionPaymentCard.propTypes = {
    preview: PropTypes.any,
    interval: PropTypes.string,
  };