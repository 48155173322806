import { useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types"

import msgs from "./PromiseResponses.json"

import 'react-toastify/dist/ReactToastify.css';

/**
 *
 * @param {Text} type : either info, success, warning, error, loading
 * @param {Text} message : message to be displayed
 * @param {Boolean} autoClose : In ms (default 3000)
 * @returns React Component
 */

export function Loader({
  type = "",
  message = "",
  autoClose = 3000,
  limit = 99,
}) {
  const toastId = useRef(null);

  useEffect(() => {
    type = type?.toUpperCase(); // Ensure type is uppercase

    if (type === "LOADING" && !toastId.current) {
      toastId.current = toast.loading(message);
    } else if (type === "SUCCESS" && toastId.current) {
      toast.update(toastId.current, {
        render: message,
        type: "success",
        autoClose,
        isLoading: false,
      });
      toastId.current = null;
    } else if (type === "ERROR" && toastId.current) {
      toast.update(toastId.current, {
        render: message,
        type: "error",
        autoClose,
        isLoading: false,
      });
      toastId.current = null;
    }
  }, [type, message, autoClose]);

  return <ToastContainer theme="colored" limit={limit} />;
}

Loader.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    autoClose: PropTypes.number,
    limit: PropTypes.number
}

export function Toast({ type, message, autoClose = 3000, limit = 99 }) {
  const toastType = type?.toLowerCase();

  if (["success", "error", "info", "warning"].includes(toastType)) {
    toast[toastType](message, { autoClose });
  }

  return <ToastContainer theme="colored" limit={limit} />;
}


Toast.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    autoClose: PropTypes.number,
    limit: PropTypes.number
}

export function Progress({ progress, status = 'done', type }) {
    const toastId = useRef(null);
    let { pending } = msgs[type];

    if (progress >= 0 && toastId === 'demmy') {
        progress = progress / 100
        if (status === 'done') {
            toast.done(toastId.current);
            setTimeout(() => {
                toastId.current = null;
            }, 500);
        } else if (status === 'pending' && !toast.isActive(toastId.current)) {
            toastId.current = toast(pending, { progress });
        } else {
            toast.update(toastId.current, { progress });
        }

    }
    return <ToastContainer />
}

Progress.propTypes = {
    progress: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string
}

export function HandlePromise({ promise, type, autoClose = 3000, message = {} }) {
    let { pending, success, error } = msgs[type] || {};
    const toastId = useRef(null);
    useEffect(() => {
        toastId.current = randomString()
    }, [])
    if (promise) {
        toast.promise(promise, {
            pending: pending || message.pending || "Loading...",
            success: success || message.success || "Success",
            error: error || message.error || "Something went wrong"
        }, { autoClose, toastId: toastId.current });

    }
    return <ToastContainer theme="colored" />
}

HandlePromise.propTypes = {
    promise: PropTypes.func,
    type: PropTypes.string,
    autoClose: PropTypes.number,
    message: PropTypes.object
}

const randomString = () => new Date().getTime().toString(36);
